import FetchStatus from 'components/common/FetchStatus';
import Layout from 'components/Layout';
import LinksGrid from 'components/LinksGrid';
import useFetch from 'hooks/useFetch';
import { Gallery } from 'interfaces/Gallery';
import React from 'react';

const GallerySwitcher = () => {
  const { status, response, error } = useFetch<{ data: Gallery[] }>(`${process.env.REACT_APP_API_ROOT}/items/gallery`, {
    params: {
      fields: 'id,title,slug,photos.*',
      filter: {
        slug: {
          _ncontains: 'group',
        },
      },
      deep: {
        photos: {
          _limit: '1',
        },
      },
    },
  });

  const entries = response?.data?.map((gallery: Gallery) => ({
    id: gallery.id,
    title: gallery.title,
    url: `/galeria/${gallery.slug}`,
    bgImage:
      (gallery?.photos && `${process.env.REACT_APP_API_ROOT}/assets/${gallery?.photos[0]?.directus_files_id}`) ??
      undefined,
  }));

  return (
    <Layout>
      <div className="content-container">
        <h1>Galeria</h1>
        <FetchStatus status={status} error={error}>
          {entries && <LinksGrid entries={entries} />}
        </FetchStatus>
      </div>
    </Layout>
  );
};

export default GallerySwitcher;
