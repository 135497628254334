import { menuEntries } from 'config/menuEntries';
import React, { FC } from 'react';
import Footer from './common/Footer';
import Navbar from './common/Navbar';

const Layout: FC = ({ children }) => (
  <>
    <Navbar siteTitle="Informatyk na europejskim rynku pracy" menuEntries={menuEntries} />
    <div className="container mx-auto px-4">{children}</div>
    <Footer />
  </>
);

export default Layout;
