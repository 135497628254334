import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer>
    <div className="bg-gray-100">
      <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-center">
        <div className="p-5 w-48 ">
          <div className="text-xs uppercase text-gray-500 font-medium">Home</div>
          <Link className="my-3 block" to="/aktualnosci">
            Aktualności{' '}
          </Link>
          <Link className="my-3 block" to="/rekrutacja">
            Rekrutacja{' '}
          </Link>
          <Link className="my-3 block" to="/instytucje-partnerskie">
            Instytucje partnerskie{' '}
          </Link>
        </div>
        <div className="p-5 w-48 ">
          <div className="text-xs uppercase text-gray-500 font-medium">Galeria</div>
          <Link className="my-3 block" to="/galeria/rekrutacja">
            Rekrutacja{' '}
          </Link>
          <Link className="my-3 block" to="/galeria/staz-zawodowy">
            Staż zawodowy{' '}
          </Link>
          <Link className="my-3 block" to="/galeria/czas-wolny">
            Czas wolny{' '}
          </Link>
          <Link className="my-3 block" to="/galeria/zakwaterowanie">
            Zakwaterowanie{' '}
          </Link>
          <Link className="my-3 block" to="/galeria/spotkania-upowszechniajace">
            Spotkania upowszechniające{' '}
          </Link>
        </div>
        <div className="p-5 w-48 ">
          <div className="text-xs uppercase text-gray-500 font-medium">Grupy</div>
          <Link className="my-3 block" to="/grupy/1">
            Grupa 1{' '}
          </Link>
          <Link className="my-3 block" to="/grupy/2">
            Grupa 2{' '}
          </Link>
          <Link className="my-3 block" to="/grupy/3">
            Grupa 3{' '}
          </Link>
          <Link className="my-3 block" to="/grupy/4">
            Grupa 4{' '}
          </Link>
        </div>
        <div className="p-5 w-48 ">
          <div className="text-xs uppercase text-gray-500 font-medium">Linki</div>
          <a className="my-3 block" href="/#">
            Erasmus+{' '}
          </a>
          <a className="my-3 block" href="/#">
            EuroMind{' '}
          </a>
        </div>
        <div className="p-5 w-48 ">
          <div className="text-xs uppercase text-gray-500 font-medium">Kontakt</div>
          <a className="my-3 block" href="https://www.zsi.kielce.pl/">
            Zespół Szkół Informatycznych im. gen. Józefa Hauke Bosaka{' '}
          </a>
          <a className="my-3 block" href="https://g.page/Informatyk_Kielce?share">
            ul. Warszawska 96
            <br />
            25 - 401 Kielce{' '}
          </a>
          <a className="my-3 block" href="tel:413676790">
            tel: 41 367 67 90{' '}
          </a>
          <div className="my-3">fax: 41 367 69 33 </div>
          <a className="my-3 block" href="mailto:szkola@zsi.kielce.pl">
            szkola@zsi.kielce.pl{' '}
          </a>
        </div>
      </div>
    </div>
    <div className="bg-gray-100 pt-2 ">
      <div
        className="flex pb-5 px-3 m-auto pt-5 border-t text-gray-800 text-sm flex-col
                md:flex-row max-w-6xl"
      >
        <div className="mt-2">
          © Copyright {new Date().getFullYear()}. Zespół Szkół Informatycznych w Kielcach - Technikum nr 7
        </div>
        <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
          <p className="right">
            Code &amp; design by{' '}
            <a className="underline" href="https://github.com/DLowHP">
              Daniel Fidor
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
