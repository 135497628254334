import Layout from 'components/Layout';
import React from 'react';

const NotFoundView = () => (
  <Layout>
    <div className="content-container text-center">
      <h1>404</h1>
      <p>Nie znaleziono podanej strony.</p>
      <a href="/">Przejdź do strony głównej.</a>
    </div>
  </Layout>
);

export default NotFoundView;
