import FetchStatus from 'components/common/FetchStatus';
import Layout from 'components/Layout';
import DOMPurify from 'dompurify';
import useFetch from 'hooks/useFetch';
import React, { FC } from 'react';
import { useParams } from 'react-router';

type GenericUrlParams = {
  slug: string;
};

type GenericData = {
  title: string;
  content: string;
};

interface GenericProps {
  slugOverride?: string;
}

const Generic: FC<GenericProps> = ({ slugOverride }) => {
  const { slug }: GenericUrlParams = useParams();
  const { status, response, error } = useFetch<{ data: GenericData[] }>(
    `${process.env.REACT_APP_API_ROOT}/items/generic?filter[slug]=${slug ?? slugOverride}`
  );

  return (
    <Layout>
      <div className="content-container">
        <FetchStatus status={status} error={error}>
          {response?.data[0] ? (
            <>
              <h1>{response?.data[0]?.title}</h1>
              <div
                // Input HTML is sanitized with DOMPurify
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(response?.data[0]?.content, { USE_PROFILES: { html: true } }),
                }}
              />
            </>
          ) : (
            <>
              <h1>404</h1>
              <span>Nie znaleziono podanej strony</span>
            </>
          )}
        </FetchStatus>
      </div>
    </Layout>
  );
};

export default Generic;
