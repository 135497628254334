import React, { FC, SVGProps } from 'react';

const ImageBrokenIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
    <path
      d="M21.71 14.54L19.21 12a1 1 0 0 0-1.42 0L15 14.84L12.21 12a1 1 0 0 0-1.42 0L8.5 14.34L6.21 12a1 1 0 0 0-1.42 0l-2.5 2.5a1 1 0 0 0-.21.33a1 1 0 0 0-.08.38V19a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-3.75a1 1 0 0 0-.08-.38a1 1 0 0 0-.21-.33zM20 19a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3.34l1.5-1.5l2.29 2.3a1 1 0 0 0 1.42 0l2.29-2.3L14.29 17a1 1 0 0 0 1.42 0l2.79-2.8l1.5 1.5zM19 2H5a3 3 0 0 0-3 3v5.26a1.17 1.17 0 0 0 0 .27v.1a1 1 0 0 0 1.66.31L5.5 9.16l2.29 2.3a1 1 0 0 0 1.42 0l2.29-2.3L14.29 12a1 1 0 0 0 1.42 0l2.79-2.8l1.77 1.78a1 1 0 0 0 1.66-.31a.28.28 0 0 0 0-.09a.88.88 0 0 0 .06-.28V5A3 3 0 0 0 19 2zm1 5.84L19.21 7a1 1 0 0 0-1.42 0L15 9.84L12.21 7a1 1 0 0 0-1.42 0L8.5 9.34L6.21 7a1 1 0 0 0-1.42 0L4 7.84V5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z"
      fill="currentColor"
    />
  </svg>
);

export default ImageBrokenIcon;
