import Label from 'components/Label';
import Layout from 'components/Layout';
import LinksGrid from 'components/LinksGrid';
import { groups } from 'config/groups';
import React, { FC } from 'react';
import { useParams } from 'react-router';

interface GroupSwitcherProps {
  showGroupLabel?: boolean;
}

const GroupSwitcher: FC<GroupSwitcherProps> = ({ showGroupLabel }) => {
  const { id }: { id?: string } = useParams();

  return (
    <Layout>
      <div className="content-container">
        {showGroupLabel && <Label url={`/grupy/${id}`} text={`Grupa ${id}`} />}
        <h1>Grupy</h1>
        <LinksGrid entries={groups} />
      </div>
    </Layout>
  );
};

export default GroupSwitcher;
