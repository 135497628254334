import classNames from 'classnames';
import React, { FC, isValidElement, ReactNode } from 'react';
import CheckIcon from '../svg/CheckIcon';
import DangerIcon from '../svg/DangerIcon';
import InfoIcon from '../svg/InfoIcon';
import WarningIcon from '../svg/WarningIcon';

type Variant = 'success' | 'info' | 'warning' | 'danger';

interface AlertProps {
  variant?: Variant;
  iconOverride?: ReactNode;
}

const Alert: FC<AlertProps> = ({ variant, iconOverride, children }) => {
  const containerClasses = 'px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center w-3/4 xl:w-2/4';
  const iconClasses = 'w-5 h-5 mr-3';
  const textClasses = '';

  const renderIcon = () => {
    if (isValidElement(iconOverride)) {
      return React.cloneElement(iconOverride, {
        className: classNames(iconOverride.props.className, iconClasses),
      });
    }

    return null;
  };

  if (variant === 'success') {
    return (
      <div className={`bg-green-200 ${containerClasses}`}>
        {renderIcon() || <CheckIcon className={`text-green-800 ${iconClasses}`} />}
        <span className={`text-green-800 ${textClasses}`}>{children}</span>
      </div>
    );
  }

  if (variant === 'warning') {
    return (
      <div className={`bg-orange-200 ${containerClasses}`}>
        {renderIcon() || <WarningIcon className={`text-yellow-800 ${iconClasses}`} />}
        <span className={`text-yellow-800 ${textClasses}`}>{children}</span>
      </div>
    );
  }

  if (variant === 'danger') {
    return (
      <div className={`bg-red-200 ${containerClasses}`}>
        {renderIcon() || <DangerIcon className={`text-red-800 ${iconClasses}`} />}
        <span className={`text-red-800 ${textClasses}`}>{children}</span>
      </div>
    );
  }

  return (
    <div className={`bg-blue-200 ${containerClasses}`}>
      {renderIcon() || <InfoIcon className={`text-blue-800 ${iconClasses}`} />}
      <span className={`text-blue-800 ${textClasses}`}>{children}</span>
    </div>
  );
};

export default Alert;
