import { Status } from 'hooks/useFetch';
import React, { FC } from 'react';
import SpinnerIcon from '../svg/SpinnerIcon';
import Alert from './Alert';

interface FetchStatusProps {
  status: Status;
  error: unknown;
}

const FetchStatus: FC<FetchStatusProps> = ({ status, error, children }) => {
  if (status === 'pending') {
    return <Alert iconOverride={<SpinnerIcon className="animate-spin" />}>Ładowanie...</Alert>;
  }

  if (status === 'error') {
    return (
      <Alert variant="danger">
        Wystąpił błąd podczas ładowania treści:{' '}
        {(error instanceof Error && error.message) || (typeof error === 'string' && error)}
      </Alert>
    );
  }

  return <>{children}</> || null;
};

export default FetchStatus;
