import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ArrowDownIcon from '../svg/ArrowDownIcon';

const Hero = () => (
  <div className="flex flex-col justify-center items-center w-full h-screen">
    {/* 
      Hero background sources: 
      https://upload.wikimedia.org/wikipedia/commons/0/05/Monumental_Plaza_de_Espa%C3%B1a_de_Sevilla.jpg
      https://nawakacje.eu/wp-content/uploads/2021/02/cork.jpg
    */}
    <div className="h-full w-full absolute">
      <LazyLoadImage
        className="hero"
        src="images/sevilla.jpg"
        alt="Hero background"
        effect="opacity"
        visibleByDefault
      />
      <LazyLoadImage
        className="hero animate-in-out"
        src="images/cork.jpg"
        alt="Hero background"
        effect="opacity"
        visibleByDefault
      />
    </div>
    <div className="w-60 h-60 rounded-full mb-7 z-20">
      <LazyLoadImage
        className="max-w-full h-full rounded-full"
        src="images/zsi-logo.png"
        alt="Logo ZSI"
        effect="opacity"
        visibleByDefault
      />
    </div>
    <ArrowDownIcon className="animate-bounce w-10 h-10" />
  </div>
);

export default Hero;
