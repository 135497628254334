import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

type MenuEntry = {
  id: number;
  name: string;
  link: string;
};

interface NavbarProps {
  siteTitle: string;
  menuEntries: MenuEntry[];
}

const Navbar: FC<NavbarProps> = ({ siteTitle, menuEntries }) => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="fixed w-screen top-0 flex items-center justify-between flex-wrap bg-blue-50 p-6 z-50 shadow-xl">
      <div className="w-full xl:w-auto flex justify-between items-center">
        <div className="flex items-center flex-no-shrink text-black mr-6">
          <Link to="/" className="font-bold text-xl md:text-2xl lg:text-3xl tracking-tight">
            {siteTitle}
          </Link>
        </div>
        <div className="block xl:hidden">
          <button
            type="button"
            className="flex flex-col w-10 h-10 items-center justify-around px-3 py-2 border rounded border-blue-200 hover:text-white hover:border-white"
            onClick={() => setOpen(!open)}
          >
            <span
              className={`block relative w-5 h-0.5 bg-black transition-all duration-300 ease-linear origin-left transform ${
                open ? 'rotate-45 translate-x-0.5' : 'rotate-0'
              }`}
            />
            <span
              className={`block relative w-5 h-0.5 bg-black transition-all duration-300 ease-linear origin-left transform ${
                open ? 'translate-x-5 opacity-0' : 'translate-x-0 opacity-1'
              }`}
            />
            <span
              className={`block relative w-5 h-0.5 bg-black transition-all duration-300 ease-linear origin-left transform ${
                open ? '-rotate-45 translate-x-0.5' : 'rotate-0'
              }`}
            />
          </button>
        </div>
      </div>
      <div className="w-full block flex-grow xl:flex xl:items-end xl:w-auto">
        <div
          // aria-hidden={!open}
          className={`text-base flex-col xl:flex-row xl:justify-end xl:text-lg xl:flex-grow last:mr-0 ${
            open ? '' : 'hidden'
          } xl:flex`}
        >
          {menuEntries.map((entry) => (
            <Link
              key={entry.id}
              to={entry.link}
              className="block mt-4 xl:inline-block xl:mt-0 hover:text-blue-400 mr-4"
              onClick={() => setOpen(!open)}
            >
              {entry.name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
