import React, { FC, SVGProps } from 'react';

const ArrowDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    focusable="false"
    width="1.25rem"
    height="1.25rem"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
    {...props}
  >
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.707 10.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L9 14.586V3a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
        fill="#ffffff"
      />
    </g>
  </svg>
);

export default ArrowDownIcon;
