import useFetch from 'hooks/useFetch';
import React from 'react';
import FetchStatus from '../common/FetchStatus';
import CalendarIcon from '../svg/CalendarIcon';

type Departure = {
  id: number;
  group: string;
  city: string;
  topic: string;
  beggining: string;
  end: string;
};

const Departures = () => {
  const { status, error, response } = useFetch<{ data: Departure[] }>(
    `${process.env.REACT_APP_API_ROOT}/items/departures`
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <FetchStatus status={status} error={error}>
        {response &&
          response?.data?.map((departure) => (
            <div key={departure.group} className="flex flex-col justify-between p-4 w-full shadow-lg rounded-2xl">
              <span className="inline-flex items-center mb-0 font-semibold">
                <span className="inline-flex justify-center items-center text-lg w-8 h-8 bg-blue-900 text-white rounded-full mr-4">
                  <span>{departure?.group}</span>
                </span>
                {departure?.city}
              </span>

              <div className="text-lg">{departure?.topic}</div>

              <div className="flex flex-row items-center text-lg">
                <CalendarIcon className="mr-4 h-8 w-8" />

                <div className="flex flex-col justify-between">
                  <time dateTime={departure?.beggining} className="mr-4">
                    <b>Od: </b>
                    {departure?.beggining}
                  </time>

                  <time dateTime={departure?.end}>
                    <b>Do: </b>
                    {departure?.end}
                  </time>
                </div>
              </div>
            </div>
          ))}
      </FetchStatus>
    </div>
  );
};

export default Departures;
