import Departures from 'components/home/Departures';
import Hero from 'components/home/Hero';
import Layout from 'components/Layout';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Home = () => (
  <Layout>
    <Hero />
    <div className="mx-auto my-10 prose lg:prose-lg max-w-100ch">
      <LazyLoadImage className="mb-6" src="../images/logotypy.png" alt="Logotypy" effect="opacity" />

      <h1>Informatyk na europejskim rynku pracy</h1>

      <p>
        Projekt &quot;Informatyk na europejskim rynku pracy&quot; jest skierowany do uczniów klas drugich, trzecich i
        czwartych o profilu technik informatyk oraz technik programista w Zespole Szkół Informatycznych w Kielcach.
        Planujemy wysłanie 2 grup uczniów do Sewilli w Hiszpanii oraz 2 grup uczniów do miejscowości Cork w Irlandii.
        <br />
        Planowane terminy wyjazdów:
      </p>

      <Departures />

      <p>
        <span className="text-center text-blue-500 font-bold">
          Projekt PO WER <br />
        </span>
        <span className="text-center text-blue-500 font-bold">
          „Międzynarodowa mobilność edukacyjna uczniów i absolwentów oraz kadry kształcenia zawodowego” <br />
        </span>
        Projekt współfinansowany przez Unię Europejską w ramach środków Europejskiego Funduszu Społecznego.
      </p>

      <p className="text-xs text-gray-400 text-right italic">
        Źródła zdjęć tytułowych:{' '}
        <a href="https://upload.wikimedia.org/wikipedia/commons/0/05/Monumental_Plaza_de_Espa%C3%B1a_de_Sevilla.jpg">
          Sevilla
        </a>{' '}
        <a href="https://nawakacje.eu/wp-content/uploads/2021/02/cork.jpg">Cork</a>
      </p>
    </div>
  </Layout>
);

export default Home;
