import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Feed from './Feed';
import GallerySwitcher from './GallerySwitcher';
import GalleryView from './GalleryView';
import Generic from './Generic';
import GroupSwitcher from './GroupSwitcher';
import Home from './Home';
import NotFoundView from './NotFoundView';

const Views = () => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>

    <Route path={['/aktualności', '/aktualnosci']} exact>
      <Feed title="Aktualności" directusSourceUrl="/items/newsfeed" />
    </Route>

    <Route path="/grupy" exact>
      <GroupSwitcher />
    </Route>

    <Route path="/grupy/:id" exact>
      <Redirect to="/grupy" />
    </Route>

    {/* <Route path="/grupy/:id" exact>
      <LinksGrid title="Podstrony" entries={groupSubpages} useGroupLinks showGroupLabel />
    </Route> */}

    <Route path="/grupy/1/blog" exact>
      <Feed title="Blog grupy 1" directusSourceUrl="/items/firstgroupblog" />
    </Route>
    <Route path="/grupy/1/whats-up" exact>
      <Feed title="What's up grupy 1" directusSourceUrl="/items/firstgroupwhatsup" />
    </Route>

    <Route path="/grupy/2/blog" exact>
      <Feed title="Blog grupy 2" directusSourceUrl="/items/secondgroupblog" />
    </Route>
    <Route path="/grupy/2/whats-up" exact>
      <Feed title="What's up grupy 2" directusSourceUrl="/items/secondgroupwhatsup" />
    </Route>

    <Route path="/grupy/3/blog" exact>
      <Feed title="Blog grupy 3" directusSourceUrl="/items/thirdgroupblog" />
    </Route>
    <Route path="/grupy/3/whats-up" exact>
      <Feed title="What's up grupy 3" directusSourceUrl="/items/thirdgroupwhatsup" />
    </Route>

    <Route path="/grupy/4/blog" exact>
      <Feed title="Blog grupy 4" directusSourceUrl="/items/fourthgroupblog" />
    </Route>
    <Route path="/grupy/4/whats-up" exact>
      <Feed title="What's up grupy 4" directusSourceUrl="/items/fourthgroupwhatsup" />
    </Route>

    {/* Commented out for future release:
    uses universal links with id pulled from url    
    <Route path="/grupy/:id/blog" exact>
      <Feed title="Blog" directusSourceUrl="/items/blog" useGroupUrl showGroupLabel />
    </Route>
    <Route path="/grupy/:id/whats-up" exact>
      <Feed title="What's up" directusSourceUrl="/items/whatsup" useGroupUrl showGroupLabel />
    </Route> */}

    <Route path="/galeria" exact>
      <GallerySwitcher />
    </Route>

    <Route path="/galeria/:slug" exact>
      <GalleryView />
    </Route>

    <Route path="/grupy/:id/galeria" exact>
      <GalleryView useGroupLink showGroupLabel />
    </Route>

    <Route path="/rekrutacja" exact>
      <Generic slugOverride="rekrutacja" />
    </Route>

    <Route path="/instytucje-partnerskie" exact>
      <Generic slugOverride="instytucje-partnerskie" />
    </Route>

    <Route path="/kontakt" exact>
      <Generic slugOverride="kontakt" />
    </Route>

    <Route path="/linki" exact>
      <Generic slugOverride="linki" />
    </Route>

    {/* Commented out for future release
    <Route path="/:slug" exact>
      <Generic />
    </Route> */}

    <Route path="/:slug" exact>
      <NotFoundView />
    </Route>
  </Switch>
);

export default Views;
