import DOMPurify from 'dompurify';
import { FeedItem } from 'interfaces/FeedItem';
import React, { FC } from 'react';

interface PostProps extends FeedItem {}

const Post: FC<PostProps> = ({ id, user_created, date_created, title, content }) => (
  <article className="flex flex-col shadow-xl rounded-2xl mb-6">
    <div className="border-gray-400 border-b-2 py-3 px-6 pb-2">
      {title !== undefined ? <span className="text-3xl font-bold block">{title}</span> : ''}
      <span className="text-gray-400">
        {user_created?.first_name ?? 'Nieznany'} {user_created?.last_name ?? 'użytkownik'}
        {', '}
      </span>
      <time className="text-gray-400" dateTime={date_created}>
        {/* Directus returns date in ISO format, 
              so all we need to display it in YYYY-MM-DD 
              format is to return first 10 letters */}
        {date_created?.substring(0, 10)}
      </time>
    </div>
    <div className="px-6">
      {content !== undefined ? (
        <div
          // Input HTML is sanitized with DOMPurify
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content, { USE_PROFILES: { html: true } }),
          }}
        />
      ) : (
        <span className="text-red-400">Nie udało się załadować treści</span>
      )}
    </div>
  </article>
);

export default Post;
