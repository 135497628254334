import axios, { AxiosRequestConfig } from 'axios';
import { useState, useCallback, useEffect } from 'react';

export type Status = 'idle' | 'pending' | 'success' | 'error';

const useFetch = <R = unknown>(
  url: string,
  options: AxiosRequestConfig = {}
): {
  status: Status;
  response: R | null;
  error: unknown;
} => {
  const [status, setStatus] = useState<Status>('idle');
  const [response, setResponse] = useState<R | null>(null);
  const [error, setError] = useState<unknown>(null);

  const fetchResponse = useCallback(async () => {
    setStatus('pending');
    setResponse(null);
    setError(null);

    try {
      const res = await axios.get<R>(url, options);
      setResponse(res.data);
      setStatus('success');
    } catch (e) {
      setError(e);
      setStatus('error');
    }
  }, [url, options]);

  useEffect(() => {
    fetchResponse();
  }, [url]);

  return {
    status,
    response,
    error,
  };
};

export default useFetch;
