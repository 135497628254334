export const groups = [
  {
    id: 0,
    title: 'Sewilla gr. 1',
    description: 'Projektowanie gier komputerowych',
    links: [
      {
        id: 0,
        title: 'Blog',
        url: '/grupy/1/blog',
      },
      {
        id: 1,
        title: "What's up",
        url: '/grupy/1/whats-up',
      },
      {
        id: 2,
        title: 'Galeria',
        url: '/grupy/1/galeria',
      },
    ],
    bgImage: '/images/sevilla.jpg',
  },
  {
    id: 1,
    title: 'Sewilla gr. 2',
    description: 'Platformy e-commerce',
    links: [
      {
        id: 0,
        title: 'Blog',
        url: '/grupy/2/blog',
      },
      {
        id: 1,
        title: "What's up",
        url: '/grupy/2/whats-up',
      },
      {
        id: 2,
        title: 'Galeria',
        url: '/grupy/2/galeria',
      },
    ],
    bgImage: '/images/sevilla.jpg',
  },
  {
    id: 2,
    title: 'Cork gr. 3',
    description: 'Staż zawodowy w firmach ICT',
    links: [
      {
        id: 0,
        title: 'Blog',
        url: '/grupy/3/blog',
      },
      {
        id: 1,
        title: "What's up",
        url: '/grupy/3/whats-up',
      },
      {
        id: 2,
        title: 'Galeria',
        url: '/grupy/3/galeria',
      },
    ],
    bgImage: '/images/cork.jpg',
  },
  {
    id: 3,
    title: 'Cork gr. 4',
    description: 'Staż zawodowy w firmach ICT',
    links: [
      {
        id: 0,
        title: 'Blog',
        url: '/grupy/4/blog',
      },
      {
        id: 1,
        title: "What's up",
        url: '/grupy/4/whats-up',
      },
      {
        id: 2,
        title: 'Galeria',
        url: '/grupy/4/galeria',
      },
    ],
    bgImage: '/images/cork.jpg',
  },
];
