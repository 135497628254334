import React, { FC } from 'react';

interface LabelProps {
  text: string;
  url?: string;
}

const Label: FC<LabelProps> = ({ text, url }) => (
  <a
    href={url}
    className="inline-block rounded-sm !text-white !no-underline
            bg-black hover:bg-gray-500 duration-300 
            text-sm font-bold 
            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
            opacity-90 hover:opacity-100"
  >
    {text}
  </a>
);

export default Label;
