import FetchStatus from 'components/common/FetchStatus';
import GalleryImage from 'components/GalleryImage';
import Label from 'components/Label';
import Layout from 'components/Layout';
import useFetch from 'hooks/useFetch';
import { Gallery } from 'interfaces/Gallery';
import React, { FC } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import { useParams } from 'react-router';

interface GalleryProps {
  showGroupLabel?: boolean;
  useGroupLink?: boolean;
}

const GalleryView: FC<GalleryProps> = ({ showGroupLabel, useGroupLink }) => {
  const { id, slug } = useParams<{ id?: string; slug?: string }>();

  const { status, response, error } = useFetch<{ data: Gallery[] }>(`${process.env.REACT_APP_API_ROOT}/items/gallery`, {
    params: {
      fields: 'id,title,slug,photos.*',
      filter: {
        slug: {
          _eq: useGroupLink ? `group${id}` : slug,
        },
      },
    },
  });

  return (
    <Layout>
      <div className="content-container">
        <FetchStatus status={status} error={error}>
          {showGroupLabel && <Label url={`/grupy/${id}`} text={`Grupa ${id}`} />}

          {response?.data[0]?.title && <h1>{response?.data[0]?.title}</h1>}

          {response?.data.length === 0 && <p className="text-xl font-bold">Nie znaleziono galerii :&#40;</p>}

          {response?.data[0]?.photos?.length === 0 ? (
            <p className="text-xl font-bold">Jeszcze nic tu nie ma, zapraszamy wkrótce :&#41;</p>
          ) : (
            <div className="grid auto-grid gap-4 zoom-fix">
              {response?.data[0]?.photos?.map((photo) => (
                <GalleryImage
                  key={photo.id}
                  file_id={photo?.directus_files_id}
                  alt={`Może dotyczyć: ${response?.data[0]?.title}`}
                />
              ))}
            </div>
          )}
        </FetchStatus>
      </div>
    </Layout>
  );
};

export default GalleryView;
