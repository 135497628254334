export const menuEntries = [
  {
    id: 0,
    name: 'Aktualności',
    link: '/aktualnosci',
  },
  {
    id: 1,
    name: 'Rekrutacja',
    link: '/rekrutacja',
  },
  {
    id: 2,
    name: 'Instytucje partnerskie',
    link: '/instytucje-partnerskie',
  },
  {
    id: 3,
    name: 'Galeria',
    link: '/galeria',
  },
  {
    id: 4,
    name: 'Grupy',
    link: '/grupy',
  },
  {
    id: 5,
    name: 'Kontakt',
    link: '/kontakt',
  },
  {
    id: 6,
    name: 'Linki',
    link: '/linki',
  },
];
