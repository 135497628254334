import { LinksGridEntry } from 'interfaces/LinksGridEntry';
import React, { FC, SyntheticEvent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useParams } from 'react-router-dom';

interface LinksGridProps {
  entries: LinksGridEntry[];
  useGroupUrls?: boolean;
}

const LinksGrid: FC<LinksGridProps> = ({ entries, useGroupUrls }) => {
  const { id }: { id?: string } = useParams();

  return (
    <div className="grid auto-grid gap-4">
      {entries &&
        entries?.map((entry) => (
          <a
            href={entry?.url}
            key={entry?.id}
            className="!no-underline !font-normal !text-white text-center group flex flex-col justify-center items-center p-4 w-full shadow-lg rounded-2xl relative overflow-hidden"
          >
            <span className="mb-6 text-3xl lg:text-5xl font-semibold z-20">{entry?.title}</span>
            {entry?.description && <span className="text-2xl lg:text-3xl mb-4 z-20">{entry?.description}</span>}

            {entry?.links &&
              entry?.links?.map((link) => (
                <Link
                  className="text-lg mb-2 last-of-type:mb-0 !text-white uppercase z-20"
                  key={link?.id}
                  to={`${link?.url}${useGroupUrls ? `_g${id}` : ''}`}
                >
                  {link?.title}
                </Link>
              ))}
            <LazyLoadImage
              className="group-hover:scale-110 gallery-img darken !m-0 z-10"
              src={entry?.bgImage ? entry?.bgImage : '/images/placeholder.jpg'}
              onError={(e: SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/images/placeholder.jpg';
                e.currentTarget.src = 'https://images.unsplash.com/photo-1615790282214-1c10ab685068?w=640&h=480';
              }}
            />
          </a>
        ))}
    </div>
  );
};

export default LinksGrid;
