import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Controlled } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import ImageBrokenIcon from './svg/ImageBrokenIcon';
import SpinnerIcon from './svg/SpinnerIcon';

interface GalleryImageProps {
  file_id?: string;
  alt?: string;
}

const GalleryImage: FC<GalleryImageProps> = ({ file_id, alt }) => {
  const [isError, setIsError] = useState<boolean>(false);

  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback((shouldZoom: boolean) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <Controlled
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      wrapStyle={{ width: '100%', height: '100%', zIndex: 40 }}
      openText="Powiększ zdjęcie"
      closeText="Zmniejsz zdjęcie"
    >
      <figure className="gallery-tile rounded-2xl !m-0">
        {!isError ? (
          <>
            <LazyLoadImage
              className="gallery-img hover:saturate-150 !m-0 z-20"
              src={`${process.env.REACT_APP_API_ROOT}/assets/${file_id}${
                isZoomed ? '?quality=100' : '?quality=10&width=640&height=480&fit=cover'
              }`}
              alt={alt}
              onError={(e: SyntheticEvent<HTMLImageElement>) => {
                e.currentTarget.onerror = null;
                setIsError(true);
              }}
            />
            <div className="w-full h-full flex flex-col items-center justify-center z-10">
              <SpinnerIcon className="animate-spin" />
              Trwa ładowanie zdjęcia...
            </div>
          </>
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center bg-red-500 text-white">
            <ImageBrokenIcon className="w-10 h-10" />
            Wystąpił błąd podczas ładowania zdjęcia :&#40;
          </div>
        )}
      </figure>
    </Controlled>
  );
};

export default GalleryImage;
