import FetchStatus from 'components/common/FetchStatus';
import Layout from 'components/Layout';
import useFetch from 'hooks/useFetch';
import React, { FC } from 'react';
import { useParams } from 'react-router';
import Post from 'components/Post';
import { FeedItem } from 'interfaces/FeedItem';
import Label from 'components/Label';

interface FeedProps {
  title: string;
  showGroupLabel?: boolean;
  directusSourceUrl: string;
  useGroupUrl?: boolean;
}

const Feed: FC<FeedProps> = ({ title, showGroupLabel, directusSourceUrl, useGroupUrl }) => {
  const { id }: { id?: string } = useParams();
  const { status, response, error } = useFetch<{ data: FeedItem[] }>(
    `${process.env.REACT_APP_API_ROOT}${directusSourceUrl}${useGroupUrl ? `_g${id}` : ''}`,
    {
      params: {
        fields: 'id,title,content,date_created,user_created.first_name,user_created.last_name',
        sort: '-date_created',
      },
    }
  );

  return (
    <Layout>
      <div className="content-container">
        {showGroupLabel && <Label url={`/grupy/${id}`} text={`Grupa ${id}`} />}

        <h1>{title}</h1>

        <FetchStatus status={status} error={error}>
          <div className="flex flex-col">
            {response && response?.data?.length === 0 ? (
              <span className="text-xl font-bold">Jeszcze nic tu nie ma, zapraszamy wkrótce :&#41;</span>
            ) : (
              response?.data?.map((item) => <Post key={item?.id} {...item} />)
            )}
          </div>
        </FetchStatus>
      </div>
    </Layout>
  );
};

export default Feed;
